<template>
    <div class="wrapper-home-page">
        <img
            class="logo-img"
            :src="require('@/assets/images/main/home-page/Logo.png')"
            data-aos="fade-down"
        />

        <div class="block-info" data-aos="zoom-in">
            <h3 class="title-text">Find your emotions through storytelling.</h3>
            <p class="desc-text">Coming soon</p>
            <div class="list-menu">
                <div class="list-menu__item hovered">
                    <p>
                        <a
                            href="https://vt.tiktok.com/ZSJKTDKMW/"
                            target="_blank"
                        >
                            <img
                                :src="
                                    require('@/assets/images/icon/tiktok.png')
                                "
                            />
                        </a>
                    </p>
                </div>
                <div class="list-menu__item hovered">
                    <p>
                        <a
                            href="https://www.instagram.com/ninetales.stories/"
                            target="_blank"
                        >
                            <img
                                :src="
                                    require('@/assets/images/icon/instagram.png')
                                "
                            />
                        </a>
                    </p>
                </div>
                <div class="list-menu__item hovered">
                    <p>
                        <a
                            href="https://www.facebook.com/ninetales.stories"
                            target="_blank"
                        >
                            <img
                                :src="
                                    require('@/assets/images/icon/facebook.png')
                                "
                            />
                        </a>
                    </p>
                </div>
                <div class="list-menu__item hovered">
                    <p>
                        <a
                            href="https://www.linkedin.com/company/nine-tales"
                            target="_blank"
                        >
                            <img
                                :src="
                                    require('@/assets/images/icon/linkedin.png')
                                "
                            />
                        </a>
                    </p>
                </div>
            </div>
        </div>

        <div class="block-menu">
            <div class="list-menu">
                <div class="list-menu__item hovered">
                    <p>
                        <router-link to="/privacy">Privacy</router-link>
                    </p>
                </div>
                <div class="list-menu__item hovered">
                    <p>
                        <router-link to="/terms">Terms</router-link>
                    </p>
                </div>
                <div class="list-menu__item hovered">
                    <p>
                        <router-link to="/contact">Contact</router-link>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.wrapper-home-page {
    a {
        color: unset;
        &:hover {
            color: unset;
            text-decoration: none;
            opacity: unset;
        }
    }
    position: relative;
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: 100vw 100vh;
    background-image: url('~@/assets/images/background/home.png');
    background-color: #fff8e4;

    .logo-img {
        position: absolute;
        top: 5%;
        left: 50%;
        transform: translate(-50%, -5%);
    }

    .block-info {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100vw;
        max-width: 1000px;
        .title-text {
            font-family: Soleil;
            font-style: normal;
            font-weight: bold;
            font-size: 48px;
            line-height: 72px;
            text-align: center;
            color: #4a5533;
        }
        .desc-text {
            margin-top: 1rem;
            font-family: Soleil;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #4a5533;
        }
        .list-menu {
            margin-top: 2rem;
            width: 100%;
            display: flex;
            justify-content: center;
            &__item {
                @include hovered;
                margin: 0 1rem;
                p {
                    font-size: 24px;
                    line-height: 32px;
                    text-align: center;
                    color: #424242;
                    font-family: Soleil;
                }
            }
        }
    }

    .block-menu {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100vw;
        max-width: 320px;
        .list-menu {
            margin-top: 2rem;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            &__item {
                @include hovered;
                position: relative;

                p {
                    font-family: Soleil;
                    font-size: 16px;
                    line-height: 24px;
                    text-align: center;
                    text-decoration: underline;
                    color: #fff8e4;
                }

                &:not(:last-child) {
                    &::after {
                        content: '/';
                        position: absolute;
                        color: #fff8e4;
                        top: 0;
                        right: 0;
                    }
                }

                &:hover {
                    p {
                        color: #bbad76;
                    }
                }
            }
        }
    }
}

@include tablet {
    .wrapper-home-page {
        background-image: url('~@/assets/images/background/home-mobile.png');
    }
}

@include mobile {
    .wrapper-home-page {
        background-image: url('~@/assets/images/background/home-mobile.png');
        .block-info {
            max-width: 80vw;
            top: 45%;
            transform: translate(-50%, -45%);
            .title-text {
                font-family: Soleil;
                font-weight: bold;
                font-size: 28px;
                line-height: 42px;
                color: #4a5533;
            }
            .desc-text {
                font-family: Soleil;
                font-size: 14px;
                line-height: 21px;
                color: #4a5533;
            }

            .list-menu {
                &__item {
                    margin: 0 0.8rem;
                }
            }
        }

        .block-menu {
            .list-menu {
                &__item {
                    p {
                        font-family: Soleil;
                        font-size: 14px;
                        line-height: 21px;
                        color: #fff8e4;
                    }
                }
            }
        }
    }
}
</style>
